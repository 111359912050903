import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';

import { Navigation, Pagination } from 'swiper/modules';

import { useRef } from 'react';
import './TechnologiesTestimonials.css';
import Group6 from '../../../src/image/TechnologiesNew/Group 26.png';
import Group7 from '../../../src/image/TechnologiesNew/Group 27.png';
import Group8 from '../../../src/image/TechnologiesNew/Group 28.png';

const testimonials = [
    {
        image: Group6,
        title: "Fast Hiring Process",
        description: "Start Building Your Apps Faster, And Speed Up The Recruitment Process With Us."
    },
    {
        image: Group7,
        title: "Top 10% Talent",
        description: "At Technolee, Get Access To The Top 10% Of The Talent Pool Of Best React Native Developers."
    },
    {
        image: Group8,
        title: "Personalized Engagement Solutions",
        description: "As Per Your Needs, Get To Choose From Flexible Models, Including Full-Time, Part-Time, Or Contract Solutions."
    }
];

const MySwiperComponent = () => {
    const prevRef = useRef(null);
    const nextRef = useRef(null);

    return (
        <div className="swiper-container">
            <Swiper
                modules={[Navigation, Pagination]}
                spaceBetween={30}
                slidesPerView={2.5} 
                navigation={{
                    prevEl: prevRef.current,
                    nextEl: nextRef.current,
                }}
                pagination={{ clickable: true }}
                grabCursor={true}
                onBeforeInit={(swiper) => {
                    swiper.params.navigation.prevEl = prevRef.current;
                    swiper.params.navigation.nextEl = nextRef.current;
                    swiper.navigation.update();
                }}
            >
                {testimonials.map((testimonial, index) => (
                    <SwiperSlide key={index} style={{ minWidth: '400px' }}>
                        <div className="swiper-card">
                            <div className="card-icon">
                                <img width={80} src={testimonial.image} alt={testimonial.title} />
                            </div>
                            <h3>{testimonial.title}</h3>
                            <p>{testimonial.description}</p>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>

            <div className="custom-navigation-buttons">
                <button ref={prevRef} className="custom-swiper-prev">
                    &#10094;
                </button>
                <button ref={nextRef} className="custom-swiper-next">
                    &#10095;
                </button>
            </div>
        </div>
    );
};

export default MySwiperComponent;
