import React from 'react';
import './TechnologiesBanner.css';
import { Link } from 'react-router-dom';
import { FaLongArrowAltRight } from "react-icons/fa";  // Updated import for long right arrow icon
import LazyLoad from 'react-lazyload';

export default function TechnologiesBanner({ image, alt, title, description, IsButton }) {
    const publicpath = window.location.origin;
    return (
        <div className={image === "" ? "banner-main" : 'flex-space-between banner-main'}>
            <div className={image === "" ? 'blog-banner' : 'banner-bg'} >
                <div className='banner-bg-heading' dangerouslySetInnerHTML={{ __html: title }}></div>
                <p className='banner-bg-subheading '>{description}</p>
                {IsButton === true && (
                    <div className='bennar-btn'>
                        <Link to='/contactus'><span>SCHEDULE A CALL US<FaLongArrowAltRight className='bannar-icon' /></span></Link>
                    </div>
                )}
            </div>
            {image && (
                <div className='banner-img'>
                    <LazyLoad height={200} offset={100}>
                        <img className='about-banner-img' src={image} alt={alt} style={{ maxWidth: '100%', maxHeight: '100%' }} />
                    </LazyLoad>
                </div>
            )}
        </div>
    );
}
